<template>
	<div class="rightSide">
		<div class="rightSide_inner">
			<div class="rightSide_inner_title">
				<p style="font-size: 30px; color: #333333">
					{{ $t("My Personal Center") }}
				</p>
			</div>

			<div class="rightSide_inner_info">
				<div class="rightSide_inner_info_content">
            <img style="width: 150px; height: 150px; margin-left: 80px" src="~@/assets/images/personal.jpg"  alt=""/>
          <div style="margin: 0 0 0 10px">
            <p style="color: #616161; font-size: 24px;">
              {{ userInfo.username }}
            </p>
            <span style="padding: 10px 0" v-if="userInfo.pid === 0">
              <el-button style="color: #218da0" size="mini" @click="ChangePasswordFlag = true">{{ $t("ChangePassword") }}</el-button>
            </span>
          </div>
				</div>
				<div class="rightSide_inner_info_text">
					<div class="rightSide_text">
						<p style="color: #757575; font-size: 16px">
							{{ $t("Membership Level") }}：
						</p>
						<p style="color: #218da0; font-size: 16px">Ordinary Use</p>
					</div>
					<div class="rightSide_text">
						<p style="color: #757575; font-size: 16px">
							{{ $t("Store Code") }}：
						</p>
						<p style="color: #218da0; font-size: 16px">
							{{ userInfo.hd_code }}
						</p>
					</div>
					<div class="rightSide_text" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
						<p style="color: #757575; font-size: 16px">
							{{ $t("Account Balance") }}：
						</p>
						<p style="color: #218da0; font-size: 16px">￥0.00</p>
					</div>
					<div class="rightSide_text">
						<p style="color: #757575; font-size: 16px">
							{{ $t("Shopping Points") }}：
						</p>
						<p style="color: #218da0; font-size: 16px">0</p>
					</div><div class="rightSide_text">
						<p style="color: #757575; font-size: 16px">
							Email：
						</p>
						<p style="color: #218da0; font-size: 16px">{{userInfo.email}}</p>
					</div>
					<!--
					<div class="rightSide_text">
						<p style="color: #757575; font-size: 16px">收货地址：</p>
						<p style="color: #218da0; font-size: 16px">广州市天河区</p>
					</div>
					-->
				</div>
			</div>

			<div class="rightSide_inner_icon">
				<div class="rightSide_inner_info_content">
					<img style="width: 120px; height: 120px; margin-left: 80px"
						src="~@/assets/images/portal-icon-1.png" />
					<div>
						<div class="rightSide_text">
							<p style="color: #333333; font-size: 18px">
								{{ $t("Shopping Cart") }}：
							</p>
							<p style="color: #218da0; font-size: 18px">
								{{ cartInfo.CountList }}
							</p>
						</div>
						<el-button type="text" style="font-size: 16px; color: #218da0; margin-left: 10px"
							@click="gotoCart">
							{{ $t("View Items in the Shopping Cart") }}. >>
						</el-button>
					</div>
				</div>
				<div class="rightSide_inner_info_content" style="margin-right: 180px">
					<img style="width: 120px; height: 120px; margin-left: 80px"
						src="~@/assets/images/portal-icon-4.png" />
					<div>
						<div class="rightSide_text">
							<p style="color: #333333; font-size: 18px">
								{{ $t("My Collection") }}：
							</p>
							<p style="color: #218da0; font-size: 18px">{{collectNum}}</p>
						</div>
						<el-button type="text" style="font-size: 16px; color: #218da0; margin-left: 10px"
							@click="toCollect">
							{{ $t("Check My Collection") }} >>
						</el-button>
					</div>
				</div>
			</div>
		</div>

    <el-dialog
      title="提示"
      :visible.sync="ChangePasswordFlag"
      width="35%">

      <el-form ref="findform" :model="updateForm" label-width="140px" class="find-form" size="40">
        <el-form-item label="Old Password">
          <el-input type="password" placeholder="Please enter your old password" class="find-input"
                    v-model="updateForm.oldPassword"></el-input>
        </el-form-item>
        <el-form-item label="New Password">
          <el-input type="password" placeholder="Please enter your new password" class="find-input"
                    v-model="updateForm.newPassword1"></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password">
          <el-input type="password" placeholder="Please enter verify new password" class="find-input"
                    v-model="updateForm.newPassword2"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="ChangePasswordFlag = false">Cancel</el-button>
          <el-button type="primary" @click="handleChangePassword">Change</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

	</div>
</template>
<script>
	import {
		SsCart
	} from "@/api/carts/carts.js";

	import {
		CountNumByUser
	} from "@/api/goods/collect.js";
	export default {
		components: {},
		data() {
			return {
				userInfo: {},
				cartInfo: {},
				collectNum:0,
        ChangePasswordFlag:false,
        updateForm:{
          oldPassword:'',
          newPassword1:'',
          newPassword2:''
        }
			};
		},
		created() {
			this.getUser();
			this.getCart();
			this.getCollectNum();
		},
		methods: {
			getCollectNum(){
				CountNumByUser().then((res)=>{
					let num = res.data.data
					this.collectNum = num
				})
			},
			//跳转到
			toCollect() {
				this.$router.push("/web/orderCenter/collectionList");
			},
			//查询购物车信息
			getCart() {
				SsCart(this.userInfo.id).then((res) => {
					this.cartInfo = res.data.data;
				});
			},
			gotoCart() {
				this.$router.push({
					path: "/web/shoppingCart",
				});
			},
			//获取用户
			getUser() {
				let user = window.sessionStorage.getItem("webUser");
				let userArr = JSON.parse(user);
				this.userInfo = userArr.user_info;
			},
      handleChangePassword(){

        if (this.updateForm.newPassword1 !== this.updateForm.newPassword2){
          this.$message.error("the two passwords are inconsistent")
        }

        if (this.updateForm.oldPassword && this.updateForm.newPassword1 && this.updateForm.newPassword2){
          this.$axios.post('/w1/user/changepass',{
            oldPassword:this.updateForm.oldPassword,
            newPassword:this.updateForm.newPassword1
          }).then(() => {
            this.$message.success("Change success，Please login again")
            location.herf = '/'
          }).catch(() => {
            this.$message.error("change faild")
          })
        }
      }
		},
	};
</script>
<style lang="scss" scoped>
	.rightSide {
		width: 1120px;
		height: 786px;
		margin: 15px 0 0 15px;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;

		&_inner {
			width: 1100px;
			height: 714px;

			&_title {
				width: 1100px;
				height: 68px;
			}

			&_info {
				width: 1100px;
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				&_content {
					width: 600px;
					height: 200px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}

				&_text {
					margin-top: 10px;
					height: 180px;
				}
			}

			&_icon {
				width: 1100px;
				height: 200px;
				margin-top: 45px;
				border-top: 1px solid #c0c0cc;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		&_text {
			margin: 10px 0 0 10px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}
</style>
